class UIManager {
    constructor() {
     this.userHasInteracted = false;

     this.setupEventListeners();
     this.cursor = { x: 0,  y: 0 }
    }

     // Event Listeners
     setupEventListeners() {
          window.addEventListener('click', this.updateUserInteractionStatus);
          window.addEventListener('keydown', this.updateUserInteractionStatus);
          window.addEventListener('touchstart', this.updateUserInteractionStatus);
          window.addEventListener('mousemove', (e) => {
               this.cursor.x = e.clientX / window.innerWidth - 0.5;
               this.cursor.y = e.clientY / window.innerHeight - 0.5;
          });
     }

     updateUserInteractionStatus() {
          this.userHasInteracted = true;
     }
}

export default UIManager;