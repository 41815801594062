import * as THREE from 'three'
import debug from '../debug'


class SceneManager {
     constructor() {
          this.scene = new THREE.Scene();
          this.debug = debug
          this.light = null
          this.createLight()
          this.addFog()
     }

     addFog() {
          this.scene.fog = new THREE.Fog(new THREE.Color('#010204'), 9.5, 1);
          // add second fog

          // this.scene.background = new THREE.Color('#010204');

          // const fogFolder = this.debug.gui.addFolder('Fog')


          // fogFolder.add(this.scene.fog, 'near', 0, 100).onChange(() => {
          //      this.scene.fog.near = this.scene.fog.near
          // })

          // fogFolder.add(this.scene.fog, 'far', 0, 100).onChange(() => {
          //      this.scene.fog.far = this.scene.fog.far
          // })

          // fogFolder.addColor(this.scene.fog, 'color').onChange(() => {
          //      this.scene.fog.color = new THREE.Color(this.scene.fog.color)
          // })

     }

     createLight() {
          const light = new THREE.PointLight('#ffffff', 10, 9.7);
          this.light = light;

          // this.debug.gui.add(this.light, 'intensity', 0, 10).onChange(() => {
          //      this.light.intensity = this.light.intensity
          // })

          // this.debug.gui.add(this.light, 'distance', 0, 100).onChange(() => {
          //      this.light.distance = this.light.distance
          // })

          // this.debug.gui.addColor(this.light, 'color').onChange(() => {
          //      this.light.color = new THREE.Color(this.light.color)
          // })

          this.light.position.set(-0.08, -0.08, 0.17);

          this.light.castShadow = true;

          this.scene.add(this.light);

          const lightHelper = new THREE.PointLightHelper(this.light);
          // this.scene.add(lightHelper);

          const ambientLight = new THREE.AmbientLight('#ffffff', 0.3);
          this.scene.add(ambientLight);

     }
}

export default SceneManager