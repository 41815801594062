// import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import * as THREE from 'three';
import debug from '../debug.js';
const glbUrl = 'models/technical_difficulties.glb';
class MeshManager {

     constructor(sceneManager) {
          this.mesh = null;
          this.GLTFLoader = new GLTFLoader();
          this.sceneManager = sceneManager;
          this.debug = debug;
          this.group = new THREE.Group();

          this.loadingManager = new THREE.LoadingManager();

          // this.handleLoading(glbUrl);
          this.createPlane();
          this.loadMeshes();

          this.group.position.set(0.46, 0, 1.48);
          this.group.rotation.set(0, -1.36, 0);
          this.sceneManager.scene.add(this.group);
     }

     createPlane() {
          const geometry = new THREE.PlaneGeometry(20, 20, 20, 20);
          const wallMaterial = new THREE.MeshStandardMaterial({
               map: new THREE.TextureLoader().load('/img/tv-pattern.png'),
               metalness: .85,
               roughness: 0,
               roughnessMap: new THREE.TextureLoader().load('/img/tv-pattern.png'),
               bumpScale: 0.05,
          });

          // Texture map
          wallMaterial.map.wrapS = THREE.RepeatWrapping;
          wallMaterial.map.wrapT = THREE.RepeatWrapping;
          wallMaterial.map.repeat.set(3, 4);

          // Bump map
          wallMaterial.roughnessMap.wrapS = THREE.RepeatWrapping;
          wallMaterial.roughnessMap.wrapT = THREE.RepeatWrapping;
          wallMaterial.roughnessMap.repeat.set(3, 3);

          const plane = new THREE.Mesh(geometry, wallMaterial);
          plane.position.set(-6, 0, 2.02);
          plane.rotation.set(0, -4.24, 0);
          plane.receiveShadow = true;
          this.sceneManager.scene.add(plane);

          const floorMaterial = new THREE.MeshStandardMaterial({
               color: '#2a2a2a',
               side: THREE.DoubleSide,
               map: new THREE.TextureLoader().load('/img/tv-noise.jpg'),
               roughnessMap: new THREE.TextureLoader().load('/img/tv-noise.jpg'),
               roughness: .16,
               metalness: .9,
               bumpMap: new THREE.TextureLoader().load('/img/tv-noise.jpg'),
               bumpScale: 0.0001,
          });

          floorMaterial.map.wrapS = THREE.RepeatWrapping;
          floorMaterial.map.wrapT = THREE.RepeatWrapping;
          floorMaterial.map.repeat.set(3, 3);

          floorMaterial.roughnessMap.wrapS = THREE.RepeatWrapping;
          floorMaterial.roughnessMap.wrapT = THREE.RepeatWrapping;
          floorMaterial.roughnessMap.repeat.set(3, 3);

          const floor = new THREE.Mesh(geometry, floorMaterial);
          floor.position.set(-1.18, -1.6, 0.42);
          floor.rotation.set(-1.56, 0, -1.1);
          this.sceneManager.scene.add(floor);

          this.group.add(plane, floor);
     }

     loadMeshes() {

          this.GLTFLoader.load(glbUrl, (gltf) => {

               this.mesh = gltf.scene;
               this.mesh.scale.set(.28, .28, .28);

               this.mesh.rotation.set(0, 0.5, 0);
               this.mesh.position.set(-3.5, -1.62, 1.74);

               gltf.scene.traverse((child) => {
                    if (child.isMesh && child.material) {


                         const material = Array.isArray(child.material) ? child.material : [child.material];
                         material.forEach((mat) => {
                              if (mat.map || mat.bumpMap || mat.normalMap || mat.emissiveMap) {

                                   if(mat.name.includes('SCREEN')) {
                                        // Teles con lineas sin señal
                                        if(mat.name.includes('001') || mat.name.includes('003')) {
                                             // do the wireframe in a random way
                                             // mat.wireframe = Math.random() > 0.5;
                                             mat.wireframe = true;
                                             this.animateEmissive(mat);
                                        }

                                        // Teles con nieve sin señal
                                        else {
                                             this.animateBlink(mat);
                                             // mat.emissiveMap = new THREE.TextureLoader().load('/img/map.png');
                                             // mat.emissiveMap.wrapS = THREE.RepeatWrapping;
                                             // mat.emissiveMap.wrapT = THREE.RepeatWrapping;
                                             // mat.emissiveMap.repeat.set(1, 1);
                                             // mat.emissiveMap = null;
                                        }

                                        mat.reflectivity = 1;
                                   }

                              }
                         });
                    }
               });

               this.mesh.receiveShadow = true;
               this.mesh.castShadow = true;

               this.group.add(this.mesh);
               this.sceneManager.scene.add(this.group);

          })
     }

     updateEmissiveMap(mat) {
          mat.emissiveMap.repeat.x += 0.00003;
          mat.emissiveMap.repeat.y -= Math.random() * 0.00003;
     }

     animateEmissive(mat) {
          this.updateEmissiveMap(mat);
          requestAnimationFrame(() => this.animateEmissive(mat));
     }

     blinkTv(mat) {
          mat.emissiveMap.offset.x = Math.random() * 0.1;
          mat.emissiveMap.offset.y = Math.random() * 0.1;
     }

     animateBlink(mat) {
          this.blinkTv(mat);
          requestAnimationFrame(() => this.animateBlink(mat));
     }

}

export default MeshManager;