import * as THREE from 'three'

class RendererManager {
     constructor(canvas, sizes) {
          this.renderer = new THREE.WebGLRenderer({ canvas });
          this.updateSize(sizes);

          this.renderer.toneMapping = THREE.ACESFilmicToneMapping;

          this.renderer.toneMappingExposure = 1;

          this.renderer.shadowMap.enabled = true;

          this.renderer.outputEncoding = THREE.sRGBEncoding;
     }

     updateSize(sizes) {
          this.renderer.setSize(sizes.width, sizes.height);
          this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
     }
}

export default RendererManager