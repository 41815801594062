import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'

class ControlsManager {
     constructor(camera, canvas) {
          this.controls = new OrbitControls(camera, canvas);
          this.controls.enableDamping = true;

          this.controls.update();
     }

     followObject(object) {
          this.controls.target.copy(object.position);
     }

     // followMouse(mouse) {
     //      this.controls.target.copy(mouse);
     // }

     update() {
          this.controls.update();
     }
}

export default ControlsManager;