import * as THREE from 'three';
import RendererManager from './classes/RendererManager.class';
import SceneManager from './classes/SceneManager.class';
import CameraManager from './classes/CameraManager.class';
import ControlsManager from './classes/ControlsManager.class';
import MeshManager from './classes/MeshManager.class';
import TextManager from './classes/TextManager.class.js';
import AudioManager from './classes/AudioManager.class.js';

import { replaceNoSignal } from './helpers.js';


import gsap from 'gsap';

class ThreeWorld {
     constructor(canvas) {
          this.sizes = {
               width: window.innerWidth,
               height: window.innerHeight
          };

          // this.debug = debug;
          this.previousTime = 0;

          this.sceneManager = new SceneManager();
          this.cameraManager = new CameraManager(this.sizes);
          this.rendererManager = new RendererManager(canvas, this.sizes);
          this.controlsManager = new ControlsManager(this.cameraManager.camera, canvas);
          this.meshManager = new MeshManager(this.sceneManager);
          this.textManager = new TextManager();
          this.audioManager = new AudioManager();

          this.loadingManager = this.meshManager.loadingManager;

          this.clock = new THREE.Clock();
          this.cursor = { x: 0,  y: 0 }
          this.cursorNode = document.querySelector('.cursor');

          this.nextStepHandler = document.querySelector('#next-step');
          this.audioHandler = document.querySelector('#sound-fx-btn');

          this.setupEventListeners();
          this.tick();
     }

     // Event Listeners
     setupEventListeners() {

          window.addEventListener('resize', () => this.onWindowResize());

          this.audioManager.setAudioSource('tv-snow');

          document.addEventListener('mousemove', (e) => {
               this.cursor.x = e.clientX / this.sizes.width;
               this.cursor.y = e.clientY / this.sizes.height;
               gsap.to(this.cursorNode, {
                   duration: 0.3,
                   left: e.clientX - this.cursorNode.offsetWidth / 2,
                   top: e.clientY - this.cursorNode.offsetHeight / 2,
                   ease: "power3.out"
               });
          });

          this.nextStepHandler.addEventListener('click', () => {

               this.textManager.orchestateText();

               if(this.textManager.activeStep === 4 ) {
                    setTimeout(() => {
                         this.nextStepHandler.classList.add('inactive');
                    }, 600);
                    return;
               };
               this.textManager.activeStep++;

               // disbale button
               this.nextStepHandler.setAttribute('disabled', true);

               setTimeout(() => {
                    this.nextStepHandler.removeAttribute('disabled');
               }, 1000);

          });

          /**
           * Next Step Handler
           */
          this.nextStepHandler.addEventListener('mouseenter', (e) => {
               if(this.textManager.activeStep > 0 ) this.audioManager.volumeFadeIn();
          });

     }

     // Methods
     onWindowResize() {
          this.sizes.width = window.innerWidth;
          this.sizes.height = window.innerHeight;
          this.cameraManager.updateSize(this.sizes);
          this.rendererManager.updateSize(this.sizes);
     }

     createEnvironment() {
          const rgbeLoader = new RGBELoader();
          rgbeLoader.load(hdri, (texture) => {
               texture.mapping = THREE.EquirectangularReflectionMapping;
               this.sceneManager.scene.background = texture;
               this.sceneManager.scene.environment = texture;
          });

     }

     parallax(light) {
          const elapsedTime = this.clock.getElapsedTime();

          const deltaTime = elapsedTime - this.previousTime;
          this.previousTime = elapsedTime;

          // Mantener la camara en el centro de la pantalla y el scroll
          this.cameraManager.camera.position.y = - (scrollY / this.sizes.height) * 1;

          // Update objects
          const parallaxX = this.cursor.x * 1.1;
          const parallaxY = - this.cursor.y * 1.1;

          // Easing or lerping
          this.cameraManager.cameraGroup.position.x += (parallaxX - this.cameraManager.cameraGroup.position.x) * deltaTime;
          this.cameraManager.cameraGroup.position.y += (parallaxY - this.cameraManager.cameraGroup.position.y) * deltaTime;

          // Light movement
          light.position.x += (parallaxX - this.sceneManager.light.position.x) * (deltaTime * 100);
          light.position.y += (parallaxY - this.sceneManager.light.position.y) * (deltaTime * 100);
     }

     // Animation Loop
     tick() {
          this.controlsManager.controls.update();
          this.rendererManager.renderer.render(this.sceneManager.scene, this.cameraManager.camera);

          this.parallax(this.sceneManager.light);
          // console.log(this.clock.elapsedTime);
          if(this.clock.elapsedTime < 15) {
               document.title = `${replaceNoSignal()} | Isidoro`;
          }
          else document.title = `Isidoro | Creative developer`;

          window.requestAnimationFrame(() => this.tick());
     }
}

export default ThreeWorld;


