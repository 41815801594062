import * as THREE from 'three'
import debug from '../debug';

class CameraManager {
     constructor(sizes) {
          this.camera = new THREE.PerspectiveCamera(50, sizes.width / sizes.height, 0.1, 100);
          this.camera.position.set(2.8, 1.4, 4.2);
          // this.camera.position.set(2.8, -0.8, 4.2);

          this.debug = debug;

          this.cameraGroup = new THREE.Group();

          this.cameraGroup.add(this.camera);

          // this.debug.gui.addFolder('Camera');
          // this.debug.gui.add(this.camera.position, 'x', -10, 10);
          // this.debug.gui.add(this.camera.position, 'y', -10, 10);
          // this.debug.gui.add(this.camera.position, 'z', -10, 10);
          // this.debug.gui.add(this.camera.position, 'z', -10, 10);
     }

     updateSize(sizes) {
          this.camera.aspect = sizes.width / sizes.height;
          this.camera.updateProjectionMatrix();
     }
}

export default CameraManager;