import UIManager from './UIManager.class.js';

import filmCamera from '/audio/film-camera.mp3';
import tvSnow from '/audio/tv-snow.mp3';

class AudioManager {
     constructor() {
          this._audio = new Audio();
          this._audio.loop = true;
          this._src = tvSnow;
          this._audio.volume = 0.5;
          this.userHasInteracted = false;
          this.uiManager = new UIManager();
          this.distanceToNextStep = 0;
          this.nextStepHandler = document.querySelector('#next-step');

          this.setupEventListeners();

     }

     setupEventListeners() {
          window.addEventListener('click', this.updateUserInteractionStatus);
          window.addEventListener('mousemove', (e) => {
               const buttonCenterX = this.nextStepHandler.offsetLeft + (this.nextStepHandler.offsetWidth / 2);
               const buttonCenterY = this.nextStepHandler.offsetTop + (this.nextStepHandler.offsetHeight / 2);

               // Calcula si el cursor esta cerca del botón
               this.distanceToNextStep = Math.sqrt(Math.pow(e.clientX - buttonCenterX, 2) + Math.pow(e.clientY - buttonCenterY, 2));


               this.volumeFadeIn(this.distanceToNextStep);
          })
     }

     pause() {
          this._audio.pause();
     }

     play() {
          this._audio.play();
     }

     setVolume(volume) {
          this._audio.volume = volume;
     }

     setAudioSource(id) {
          switch (id) {
               case 'film-camera':
                    this._audio.src = filmCamera;
                    break;
                    case 'tv-snow':
                         this._audio.src = tvSnow;
                         this._audio.volume = 0.02;
                    break;
               default:
                    break;
          }
     }

     volumeFadeIn() {

          const fadeInStartDistance = 300; // distancia en px desde la cual comienza el fade in
          const maxVolume = 0.005; // volumen máximo


          // Si el cursor está dentro del rango, ajusta el volumen
          if (this.distanceToNextStep < fadeInStartDistance) {

               // Aumenta volumen si se esta acercando
               this._audio.volume = maxVolume - (this.distanceToNextStep / fadeInStartDistance) * maxVolume;

               // Si
               this._audio.play();
          }

          // Si el cursor está fuera del rango, pausa el audio
          else {
               this._audio.pause();
          }
     }


}

export default AudioManager;