import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";
import Splitting from "splitting";
import Lenis from '@studio-freight/lenis'
import * as THREE from 'three';

class TextManager {

     constructor() {

          this.spinnerNodes = document.querySelectorAll('.slot');
          this.clock = new THREE.Clock();
          this.elapsedTime = 0;
          this.loaderHasFinished = false;
          this.activeStep = 0;
          this.handleLoader();

     }

     handleLoader() {
          this.elapsedTime = this.clock.getElapsedTime();

          this.spinnerNodes.forEach((node, i) => {

               if(i !== 3) node.textContent = (Math.floor(Math.random() * 9) + 1);
               else node.textContent = ['&', '%', '$', '*', '@', '#'][Math.floor(Math.random() * 6)];

               const interval = setInterval(() => {
                    if(i !== 3) node.textContent = (Math.floor(Math.random() * 9) + 1);
                    else ['&', '%', '$', '*', '@', '#'][Math.floor(Math.random() * 6)];;
               }, 1000 + ((i * Math.random() * (10 * Math.random())) * 100));

               setTimeout(() => {
                    clearInterval(interval);
                    node.classList.remove('animate')

                    if(i === 0) node.textContent = 1
                    if(i === 1) node.textContent = 0
                    if(i === 2) node.textContent = 0
                    if(i === 3) node.textContent = '%'

               }, 3500);
          });

          setTimeout(() => {
               document.querySelector('.loader').classList.add('inactive');
               document.querySelector('.cursor').classList.add('active');
          }, 4200);

          setTimeout(() => {
               document.querySelector('.main').classList.add('active');
               this.orchestateText();
               this.activeStep++;
          }, 5000);
     }

     orchestateText() {

          const textNodes = document.querySelectorAll('[data-step]');
          const activeWordChars = this.getSplitText(textNodes[this.activeStep], 'chars');
          const socials = document.querySelectorAll('.socials a');

          // Set all the words to opacity 0 except the active one
          if(this.activeStep === 0) {
               textNodes.forEach((node, i) => {
                    if(i !== this.activeStep) {
                         gsap.set(node, {
                              opacity: 0,
                         });
                    }
               });
          }

          // Exits
          if(this.activeStep > 0) {
               const prevWordChars = this.getSplitText(textNodes[this.activeStep - 1], 'chars');
               prevWordChars[0].chars.forEach((char, i) => {
                    gsap.to(char, {
                         'will-change': 'opacity, transform',
                         duration: 2,
                         ease: 'power4',
                         opacity: 0,
                         rotationX: 90,
                         filter: 'blur(30px)',
                         delay: gsap.utils.random(0.1, 0.5),
                    })
               });
          }

          // Entrances
          activeWordChars[0].chars.forEach((char, i) => {
               gsap.set(textNodes[this.activeStep], {
                    opacity: 1
               })
               gsap.from(char, {
                    'will-change': 'opacity, transform',
                    transformOrigin: '50% 100%',
                    opacity: 0,
                    rotationX: -90,
                    duration: 2,
                    filter: 'blur(30px)',
               })
               gsap.to(char, {
                    'will-change': 'opacity, transform',
                    duration: 3,
                    ease: 'power4',
                    opacity: 1,
                    rotationX: 0,
                    filter: 'blur(0px)',
                    delay: gsap.utils.random(0.1, 0.5)
               }, '<')
          });

          if(this.activeStep === 4) {
               gsap.to(socials, {
                    duration: 1,
                    opacity: 1,
                    stagger: 0.1,
                    ease: 'power4',
                    pointerEvents: 'all'
               })
          }
     }



     getSplitText(text, by = 'words') {
          return Splitting({
               target: text,
               by: by
          });
     }

}

export default TextManager