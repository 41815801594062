// GUI
import GUI from 'lil-gui'

// Stats
import Stats from 'three/examples/jsm/libs/stats.module'

/**
 * Lil GUI
 */
const debug = {
     // gui: new GUI(),
     stats: new Stats(),
}

/**
 * Stats
*/
// document.body.appendChild(debug.stats.dom)


export default debug;