export function replaceNoSignal() {
     const originalString = "NO SIGNAL";
     let chars = Array.from(originalString); // Convert string to array for easy manipulation
     const maxReplacements = Math.floor(chars.length / 2); // At most 50% of characters

     // Define the allowed replacement characters
     const replacements = '0123456789!@#$%^&*'.split('');

     // Randomly decide how many characters to replace (up to maxReplacements)
     let numReplacements = Math.floor(Math.random() * (maxReplacements + 1));

     // Randomly replace characters
     while (numReplacements > 0) {
          let pos = Math.floor(Math.random() * chars.length); // Position to replace

          if (chars[pos] !== originalString[pos]) {
               // Already replaced, skip
               continue;
          }

          // Replace with a random character from the allowed replacements
          chars[pos] = replacements[Math.floor(Math.random() * replacements.length)];
          numReplacements--;
     }

     // Return the modified string
     return chars.join('');
}